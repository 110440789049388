import React from "react";

export const AppFooter = () => {
  return (
    <div className="layout-footer">
      <div className="wrapper p-d-flex p-ai-center">
        <span className="footer-text" style={{ marginLeft: "5px" }}>
          &nbsp;
        </span>
      </div>
    </div>
  );
};
